import http from "@/middleware";

export const idlingDischargeApi = {
    async getIdlingDischargeFixedRange(filters) {
        const result = await http.post("/api/varilla-report/idling-fixed-range", filters);
        return result.data;
    },
    async getIdlingCustomTime(filters) {
        const lang = localStorage.getItem("locale");
        filters.lang = lang;
        const result = await http.post("/api/varilla-report/idlingCustomTime", filters);
        return result.data;
    }
};

export default idlingDischargeApi;
