import reportStorageApi from '@/api/report-storage.api';
import { breadcrumbService } from "@/business/breadcrumb.service";
import { configurationService } from "@/business/configurationService";
import dischargeDetailService from '@/business/dischargeDetail.service';
import { filterService } from "@/business/filterService";
import { idlingDischargeService } from "@/business/idling-discharge.service";
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue';
import DischargeDetailComponent from '@/components/discharge-detail/DischargeDetailComponent.vue';
import FilterComponent from "@/components/filter/FilterComponent.vue";
import { REPORT_TYPES, SnackbarStyle } from '@/constants/constants';
import i18n from "@/i18n";
import middleware from "@/middleware";
import store from "@/store/store";
import { mapActions, mapMutations } from "vuex";
import { unitParser } from '@colven/common-domain-lib/lib';

export default {
    components: {
        BreadcrumbComponent,
        DischargeDetailComponent,
        FilterComponent
    },
    data: () => ({
        model: {
            charts: [],
            maps: [],
            table: {
                headers: [],
                data: [],
            },
        },
        middleware: middleware,
        loading: false,
        title: i18n.t('idlingDischarges'),
        tableDataKey: 'number',
        tableFilterRules: {},
        disableRefreshTable: true,
        loadingTable: false,
        getDetailsForTable: null,
        getDetailsForChart: null,
        tableConfig: {},
        // botones de cada fila
        rowActions: [{
            id: 1,
            name: i18n.t('detail'),
            color: 'blue',
            icon: 'mdi-file-document-outline',
        }],
        highlightRow: null,
        // modelo para el componente del detalle
        detailModel: dischargeDetailService.getComponentModel(),
        // tabs para el breadcrumb
        tabs: [{
            id: 'tab-summary',
            name: i18n.t('dischargeSummary.breadcrumbTabSummaryIdling')
        }],
        // tab seleccionada
        currentTab: 'tab-summary',
        // botones del breadcrumb
        breadcrumbButtons: [],
        // key del detalle para guardar la configuración
        detailKey: REPORT_TYPES.IDLING_DETAIL,
        reportDetails: '',
        filters: filterService.getFilterModel(),
        initPosition: null,
        showFilters: false,
        maxQuantityLimit: 10,
        orderFilterWorstBestPropertyName: unitParser.changeUnit(i18n.t(
            "dischargeSummary.filterProperties.worstBestProperty"
        ), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) }),
        dataForRangeFilter: {
            showRangeData: true,
            minLimit: 0,
            maxLimit: 1000,
            propertyName: unitParser.changeUnit(i18n.t("dischargeSummary.filterProperties.idlingRangeProperty"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) }),
        },
        selectedReferences: []
    }),
    created() {
        this.breadcrumbButtons.push(
            {
                id: 'setting-btn',
                icon: 'settings',
                show: () => { return true },
                disable: () => { return false },
                function: this.selector.bind(this)
            }
        );
    },
    async mounted() {
        this.loading = true;
        this.resetBreadcrumbSelectors();
        const tableConfiguration = await configurationService.get("idlingSummary.table");
        this.tableConfig = (tableConfiguration && tableConfiguration.data) || {};
        try {
            const reportKey = this.$route.query.key
            const report = reportKey && await reportStorageApi.getReport(reportKey);
            let idlingDischarges = null;
            let filters = null;
            if (report && report.filters) {
                filters = report.filters;
            } else {
                if (Object.keys(this.$route.params).length !== 0) {
                    filters = this.$route.params;
                    idlingDischarges = await idlingDischargeService.getIdlingDischargeSummary(filters);
                } else {
                    const configuration = await configurationService.get(REPORT_TYPES.IDLING_SUMMARY);
                    filters = configuration && configuration.data && configuration.data.filters
                        ? configuration.data.filters
                        : filterService.getFilterModel();
                }
            }
            if (report && !idlingDischarges) {
                idlingDischarges = await idlingDischargeService.generateReportByData(report.data);
            }
            this.filters = filters;
            if (!idlingDischarges && this.$route.params && this.$route.params.dateData) {
                this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
            } else {
                this.setReport(idlingDischarges);
            }
        } finally {
            this.loading = false;
        }
    },
    beforeDestroy() {
        this.closeReportDefer();
    },
    methods: {
        ...mapActions({
            showSnackbar: "snackbar/showSnackbar"
        }),
        ...mapMutations('reportDefer', {
            showReportDefer: 'showReportDefer',
            closeReportDefer: 'closeReportDefer',
            commitVisible: 'commitVisible'
        }),
        async setReportByKey(reportKey) {
            if (reportKey) {
                const report = reportKey && await reportStorageApi.getReport(reportKey);
                let idlingDischarges = null;
                let filters = null;
                if (report && report.filters) {
                    filters = report.filters;
                } else {
                    if (Object.keys(this.$route.params).length !== 0) {
                        filters = this.$route.params;
                        idlingDischarges = await idlingDischargeService.getIdlingDischargeSummary(filters);
                    } else {
                        const configuration = await configurationService.get(REPORT_TYPES.IDLING_SUMMARY);
                        filters = configuration && configuration.data && configuration.data.filters
                            ? configuration.data.filters
                            : filterService.getFilterModel();
                    }
                }
                if (report && !idlingDischarges) {
                    idlingDischarges = await idlingDischargeService.generateReportByData(report.data);
                }
                this.filters = filters;
                this.setReport(idlingDischarges);
            }
        },
        setReport(idlingDischarges) {
            if (idlingDischarges) {
                this.initPosition = idlingDischarges.initPosition;
                this.model.charts.cleanAndUpdate(idlingDischarges.chart);
                this.model.maps.cleanAndUpdate(idlingDischarges.maps);
                this.model.table.headers.cleanAndUpdate(idlingDischarges.table.headers);
                this.model.table.data.cleanAndUpdate(idlingDischarges.table.data);
                this.resetBreadcrumbSelectors();
                breadcrumbService.setSummaryDetailsByFilters(this.filters, 'IDLING');
                this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "IDLING");
                this.getDetailsForTable = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
                this.getDetailsForChart = breadcrumbService.getFilterDetailsHTMLExport(this.filters, "IDLING");
            } else {
                this.cleanData();
                this.showFilters = true;
            }

        },
        cleanData() {
            this.initPosition = null;
            this.model.charts.cleanAndUpdate([]);
            this.model.maps.cleanAndUpdate([]);
            this.model.table.headers.cleanAndUpdate([]);
            this.model.table.data.cleanAndUpdate([]);
            this.resetBreadcrumbSelectors();
            breadcrumbService.setSummaryDetailsByFilters(this.filters, 'IDLING');
            this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "IDLING");
            this.getDetailsForTable = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
            this.getDetailsForChart = breadcrumbService.getFilterDetailsHTMLExport(this.filters, "IDLING");
        },
        closeFilter() {
            this.showFilters = false;
        },
        disableSelectorButton() {
            this.showFilters = false;
        },
        selector() {
            this.showFilters = !this.showFilters;
        },
        async saveFilterChanges() {
            configurationService
                .save(REPORT_TYPES.IDLING_SUMMARY, { filters: this.filters })
                .then(() => {
                    // this.showSnackbar({
                    //     visible: true,
                    //     text: i18n.t("user.configuration.saveSuccess"),
                    //     timeout: 10000,
                    //     style: SnackbarStyle.SUCCESS
                    // });
                })
                .catch(() => {
                    // this.showSnackbar({
                    //     visible: true,
                    //     text: i18n.t("user.configuration.saveError"),
                    //     timeout: 10000,
                    //     style: SnackbarStyle.ERROR
                    // });
                });
            this.closeFilter();
            this.cleanData();
            if (this.currentTab !== "tab-summary") {
                this.tabSelected("tab-summary");
            }
            this.loading = true;
            try {
                const result = await idlingDischargeService.getIdlingDischargeSummary(this.filters);
                if (result) {
                    this.setReport(result);
                } else {
                    if (this.loading) this.commitVisible({ visible: true })
                    this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
                }
            } finally {
                this.loading = false;
            }

        },
        resetBreadcrumbSelectors() {
            store.dispatch("breadcrumb/setEntitySelectorItems", []);
        },
        /**
         * Método que recibe los datos de la fila clickeada, obtiene el nro de fila que la identifica y llama a filtrar las descargas en el mapa.
         * @param {*} rowData
         */
        filterIdlingDischargesByRowClick(rowData) {
            this.filterIdlingDischarges(rowData.thingName);
            this.highlightSelectedRow(rowData.number);
            //TODO: esta solución no es la correcta; se debería calcular los centros correspondientes y no tomar uno de los puntos existentes para centrar.
            const lastDischIndex = rowData.discharges.length - 1;
            const lastDischargePos = lastDischIndex >= 0 && rowData.discharges[lastDischIndex] ?
                {
                    lat: rowData.discharges[lastDischIndex].latEnd,
                    lng: rowData.discharges[lastDischIndex].longEnd
                }
                : null;
            if (lastDischargePos) {
                this.centerMapAndSetZoom(lastDischargePos.lat, lastDischargePos.lng, 2.5);
            }
        },
        /**
         * Centrar el mapa y setea el zoom luego de hacer click en una fila de la tabla
         * TO DO: implementar en el componente MapComponent una prop reactiva o modelo y un watch para ejecutar el centrado y así no usar referencias al DOM físico
         * @param {*} selectedItem
         */
        centerMapAndSetZoom(lat, lng, zoom) {
            const mapRef = this.$refs.idlingDischargeSummaryReportMap;
            if (mapRef != null && lat != null && lng != null && zoom != null) {
                mapRef.centerMapAndSetZoom([lat, lng], zoom);
            }
        },
        /**
         * En base a la fila seleccionada se obtienen las referencias a filtrar en el mapa
         * @param {*} refname
         */
        filterIdlingDischarges(refname) {
            const groupRef = [{
                name: this.model.maps[0].groups[0].name,
                refs: [refname]
            }];
            this.selectedReferences.cleanAndUpdate(groupRef);
        },
        refreshTable() {
        },
        saveConfig(data) {
            configurationService.save("idlingSummary.table", data)
                .then(() => {
                    this.showSnackbar({
                        visible: true,
                        text: i18n.t("user.configuration.saveSuccess"),
                        timeout: 10000,
                        style: SnackbarStyle.SUCCESS
                    });
                })
                .catch(() => {
                    this.showSnackbar({
                        visible: true,
                        text: i18n.t("user.configuration.saveError"),
                        timeout: 10000,
                        style: SnackbarStyle.ERROR
                    });
                });
        },
        // eslint-disable-next-line no-unused-vars
        rowButtonClicked(button, data) {
            switch (button.id) {
                case 1:
                    this.goToDetail(data)
                    break;
                default:
                    console.log('default');
            }
        },
        /**
         * Ir al detalle
         * @param {*} selectedItem
         */
        goToDetail(selectedItem) {
            if (this.tabs.length === 1) {
                this.tabs.push({
                    id: 'tab-detail',
                    name: i18n.t('detail')
                })
                dischargeDetailService.loadBreadcrumbSelector(this.model.table.data,
                    {
                        thingId: selectedItem.thingId,
                        thingName: selectedItem.thingName
                    })
            }
            this.currentTab = 'tab-detail'
        },
        /**
         * Cambia los datos del detalle cuando se selecciona otro item del selector del breadcrumb
         * @param {*} selectedItem
         */
        changeDetail(selectedItem) {
            const dataSelected = this.model.table.data.find(element => element.thingId === selectedItem.thingId)
            this.detailModel = dischargeDetailService.getComponentModel(dataSelected)
            this.detailModel.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.filters, null, this.detailModel.thing.name);
        },
        /**
         * Método para resaltar una fila en la tabla
         * @param {*} number
         */
        highlightSelectedRow(number) {
            if (number != null) {
                this.highlightRow = null;
                const that = this;
                this.$nextTick(() => {
                    that.highlightRow = number;
                });
            }
        },
        /**
         * cuando se hace click en un marcador del mapa, se resalta la fila en la tabla
         * @param {*} number
         */
        onMapClick({ number }) {
            this.highlightSelectedRow(number);
        },
        filtersChanged(data) {
            configurationService.save("idlingSummary.map", { currentLayer: data.currentLayer });
        },
        /**
         * Acción ejecutada al cambiar de tab (breadcrumb)
         * @param {*} id
         */
        tabSelected(id) {
            this.currentTab = id
            if (this.currentTab === 'tab-summary' && this.tabs.length === 2) {
                this.tabs.pop();
                this.resetBreadcrumbSelectors();
            }
        }
    },
};