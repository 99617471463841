import idlingDischargeApi from "@/api/idling-discharge.api";
import { configurationService } from "@/business/configurationService";
import { reportsColors } from "@/constants/constants";
import { DISCHARGE_CHAT_JSON_TEMPLATE, DISCHARGE_MAP_POINT_JSON_TEMPALTE, DISCHARGE_MAP_REFERENCE_TEMPLATE, IDLING_CHART_DATASET_TEMPLATE, IDLING_MAP_JSON_TEMPLATE, TABLE_DATA_DISCHARGE_TEMPLATE, TABLE_HEADERS_IDLING } from "@/constants/discharge.constant";
import i18n from "@/i18n";
import store from "@/store/store";
import { ReportConversionUtil, TimeRanges, unitParser } from '@colven/common-domain-lib/lib';
import * as deepcopy from "deepcopy";
import { parseTimestamp, processFilters } from "../tools/functions";
import moment from "moment";

export const idlingDischargeService = (function () {
    "use strict";
    const mapTemplate =
        '`<h4><a href="#" class="filterMapPopup">${filterMapTranslation}</a> - ${thingName}</h4><hr>${fuelDischargeTranslation}: ${fuelDischargeValue}<br> ${dateTranslation}: ${dateValue}<br><a href="https://www.google.com/maps/search/?api=1&query=${discharge.latEnd}%2C${discharge.longEnd}" class="googleLink" target="_blank">${goToGoogleTranslation}</a> <script> document.querySelector(".filterMapPopup").onclick = ()=>{console.log(filterMap);filterMap(marker.businessData.refname)};</script>`';

    async function getIdlingDischargeSummary(filters) {
        const filterData = processFilters(filters);
        let data = null;
        if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
            filterData.originalFilters = filters;
            await idlingDischargeApi.getIdlingCustomTime(filterData);
            return null;
        } else {
            data = await idlingDischargeApi.getIdlingDischargeFixedRange(filterData);
        }
        return await generateReportByData(data);
    }

    async function generateReportByData(data) {
        const result = {};

        //filtro de descargas en ralentí menores o iguales a cero
        data.forEach(thing => {
            const thingIdling = thing.idling.filter((disch) => disch.totalIdling > 0);
            thing.idling.cleanAndUpdate(thingIdling);
        });
        // data.forEach(thing => {
        //     thing.idling = groupFieldByDistance(thing.idling);
        // });
        const { chart, map, table, initPosition } = await processReportData(data);
        result.table = table;
        result.maps = map;
        result.chart = chart;
        result.initPosition = initPosition;
        return result;
    }

    async function getIdlingDashboard(filters) {
        const filterData = processFilters(filters);
        const data = await idlingDischargeApi.getIdlingDischargeFixedRange(filterData);


        //filtro de descargas en ralentí menores o iguales a cero
        data.forEach(thing => {
            const thingIdling = thing.idling.filter((disch) => disch.totalIdling > 0);
            thing.idling.cleanAndUpdate(thingIdling);
        });
        // data.forEach(thing => {
        //     thing.idling = groupFieldByDistance(thing.idling);
        // });
        return generateChart(data);
    }

    // function groupFieldByDistance(dischargesIdlings) {
    //     let result = [];
    //     if (dischargesIdlings && dischargesIdlings.length > 0) {
    //         let newDischargesIdlings = JSON.parse(JSON.stringify(dischargesIdlings));
    //         let lastDischargeIdling = null;
    //         for (const dischargeIdling of newDischargesIdlings) {
    //             //Obtener el tiempo en ralentí de esta descarga y sumarla al total
    //             //se le suma 10 minutos a cada descarga porque el equipo manda el ralenti luego de 10' 
    //             //Además agregarla como property de la descarga para el detalle y hacer el cálculo una única vez
    //             setDurationTime(dischargeIdling);

    //             const shouldGroupDischarge = dischargeIdling.odometerEnd ?
    //                 lastDischargeIdling && ((parseInt(lastDischargeIdling.odometerEnd) + 20) >= parseInt(dischargeIdling.odometerEnd)) :
    //                 lastDischargeIdling && ((parseInt(lastDischargeIdling.hourmeterEnd) + 1800) >= parseInt(dischargeIdling.hourmeterEnd))

    //             if (shouldGroupDischarge) {
    //                 if (dischargeIdling.totalDischarged) {
    //                     lastDischargeIdling.tanks[dischargeIdling.tankKey] = dischargeIdling.totalDischarged || 0;
    //                 }
    //                 lastDischargeIdling.totalDischarged += dischargeIdling.totalDischarged || 0;
    //                 lastDischargeIdling.totalIdling += dischargeIdling.totalIdling || 0;
    //                 lastDischargeIdling.duration += dischargeIdling.duration || 0;
    //                 lastDischargeIdling.fuelLevelEnd = dischargeIdling.fuelLevelEnd;
    //             } else {
    //                 lastDischargeIdling = dischargeIdling;
    //                 const tanks = {
    //                     TANK_1: 0,
    //                     TANK_2: 0,
    //                     TANK_3: 0,
    //                     TANK_4: 0
    //                 }
    //                 if (dischargeIdling.totalDischarged) {
    //                     tanks[dischargeIdling.tankKey] = dischargeIdling.totalDischarged || 0;
    //                 }
    //                 lastDischargeIdling.tanks = tanks;
    //                 result.push(lastDischargeIdling);
    //             }
    //         }
    //     }
    //     return result;
    // }

    function initChart(data) {
        // CHART init
        let chart = deepcopy(DISCHARGE_CHAT_JSON_TEMPLATE);       
        chart.options.tooltips.callbacks.label = (data) => {
            return `${i18n.t('dischargeSummary.chart.dataset.labelIdling')}: ${data.value} ${unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.VOLUME)}`
        }
        chart.id = new Date().getTime();
        const maxIdling = getMaxCountIdlingDischarges(data);
        chart.data.datasets = getInitDataset(maxIdling);
        return { chart, maxIdling };
    }

    function generateChart(data) {
        const { chart, maxIdling } = initChart(data);
        if (data && data.length > 0) {
            for (const thing of data) {
                processChartThing(chart, thing, maxIdling);
            }
        }
        return [chart];
    }

    function processChartThing(chart, thing, maxIdling) {
        const internationalization = store.getters['user/getInternationalization'];
        const internationalizationType = internationalization.unit;
        chart.data.labels.push(thing.thingName);
        for (let i = 0; i < maxIdling; i++) {
            chart.data.datasets[i].data.push(thing.idling[i] ? unitParser.parseVolumen(thing.idling[i].totalIdling, internationalizationType, false) : 0);
            // chart.data.datasets[i].formattedTooltipData.label.push(
            //     thing.idling[i] ? unitParser.parseVolumen(thing.idling[i].totalIdling, internationalizationType, true) : unitParser.parseVolumen(thing.idling[i].totalIdling, internationalizationType, true)
            // );
        }
    }

    async function processReportData(data) {
        // CHART init
        const { chart, maxIdling } = initChart(data);

        //MAP init
        const map = deepcopy(IDLING_MAP_JSON_TEMPLATE);

        //TABLE init
        const tableHeaders = deepcopy(TABLE_HEADERS_IDLING);
        for (const header of tableHeaders) {
            header["text"] = unitParser.changeUnit(i18n.t(header["text"]), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) });
        }
        const tableDataFinal = [];

        //init position
        let initPosition = {
            lat: null,
            long: null,
            zoom: 2.5
        }

        const mapConfig = await configurationService.get("idlingSummary.map");
        if (mapConfig) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }

        const internationalization = store.getters['user/getInternationalization'];
        const internationalizationType = internationalization.unit;

        if (data && data.length > 0) {
            data.forEach((thing, index) => {
                let totalIdlingTime = 0; //EN SEGUNDOS
                if (thing.totalIddlingSort <= 0) {
                    return;
                }
                //CHART
                processChartThing(chart, thing, maxIdling);

                //MAP
                const reference = deepcopy(DISCHARGE_MAP_REFERENCE_TEMPLATE);
                reference.name = thing.thingName;
                reference.title = thing.thingName;
                map.groups[0].references.push(reference);
                let idlingId = thing.idThing;
                thing.idling.forEach((discharge, counter) => {
                    setDurationTime(discharge)
                    /* eslint-disable no-unused-vars */
                    const thingName = thing.thingName;
                    const dateTranslation = i18n.t("dischargeSummary.map.popup.date");
                    const dateValue = parseTimestamp(discharge.trackTimestampEnd)
                    const fuelDischargeTranslation = i18n.t("dischargeSummary.map.popup.fuelIdlingDischarge");
                    const filterMapTranslation = i18n.t("dischargeSummary.map.popup.filterMap");
                    const goToGoogleTranslation = i18n.t("dischargeSummary.map.popup.goToGoogleTranslation");
                    const fuelDischargeValue = unitParser.parseVolumen(discharge.totalIdling, internationalizationType, true);
                    /* eslint-enable no-unused-vars */
                    const dischargePoint = deepcopy(DISCHARGE_MAP_POINT_JSON_TEMPALTE);
                    dischargePoint.id = idlingId + counter;
                    dischargePoint.lat = discharge.latEnd;
                    dischargePoint.lng = discharge.longEnd;
                    dischargePoint.value = thingName;
                    dischargePoint.marker.businessData = { hasLinks: true };
                    dischargePoint.marker.businessData.popup = eval(mapTemplate);
                    map.groups[0].series[0].points.push(dischargePoint);
                    dischargePoint.marker.businessData.number = index + 1;
                    dischargePoint.marker.businessData.refname = reference.name;
                    initPosition.lat = discharge.latEnd;
                    initPosition.long = discharge.longEnd;
                    totalIdlingTime += discharge.duration;
                })

                //TABLE
                const tableData = deepcopy(TABLE_DATA_DISCHARGE_TEMPLATE);
                tableData.thingId = thing.idThing;
                tableData.thingName = thing.thingName;
                tableData.serviceTypeKey = thing.serviceTypeKey;
                tableData.serviceType = thing.serviceTypeKey && thing.serviceTypeKey !== '' ? i18n.t("table.data.serviceType." + thing.serviceTypeKey) : '';
                tableData.nameDriver = thing.currentDriverName;
                tableData.positionTimestamp = parseTimestamp(thing.lastTrackTimestamp)
                tableData.communicationTimestamp = parseTimestamp(thing.lastComunicationTimestamp);
                tableData.dischargesAmount = thing.idling.length.toString();
                tableData.totalLiters = unitParser.parseVolumen(thing.totalIddlingSort, internationalizationType, true);
                tableData.positionTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastTrackTimestamp);
                tableData.communicationTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastComunicationTimestamp);
                tableData.number = index + 1;
                // descargas por ralenti para el detalle (unifico en la prop dsicharges para poder reutilizar el componente)
                tableData.discharges = thing.idling;
                tableData.totalIdlingTime = ReportConversionUtil.secondsToString(totalIdlingTime);

                tableDataFinal.push(tableData);
            })
        }
        if (!initPosition.lat) {
            initPosition = null;
        }
        return {
            chart: [chart],
            map: [map],
            table: {
                headers: tableHeaders,
                data: tableDataFinal
            },
            initPosition
        }
    }

    function setDurationTime(discharge) {
        if (discharge.trackTimestampStart && discharge.trackTimestampEnd) {
            const initTimestamp = moment.unix(discharge.trackTimestampStart);
            const endTimestamp = moment.unix(discharge.trackTimestampEnd);
            const idlingTime = (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds() + 600; //600 segundos = 10 minutos
            discharge.duration = idlingTime;
        }
    }

    function getMaxCountIdlingDischarges(summaries) {
        let result = 0;
        if (summaries && summaries.length > 0) {
            for (const thingSummary of summaries) {
                const length = thingSummary.idling && thingSummary.idling.length || 0;
                if (length > result) {
                    result = length;
                }
            }
        }
        return result;
    }

    function getInitDataset(maxIdling) {
        const result = [];
        for (let i = 0; i < maxIdling; i++) {
            const idlingDataset = deepcopy(IDLING_CHART_DATASET_TEMPLATE);
            idlingDataset.backgroundColor = (i % 2 !== 0) ? reportsColors.IDLING_EVEN : reportsColors.IDLING_ODD;
            idlingDataset.label = i18n.t('dischargeSummary.chart.dataset.labelIdling');
            result.push(idlingDataset);
        }
        return result;
    }

    return {
        getIdlingDischargeSummary,
        getIdlingDashboard,
        generateReportByData
    };
})();